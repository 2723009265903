import { render, staticRenderFns } from "./SvgIcon.vue?vue&type=template&id=01ffae7e&scoped=true"
import script from "./SvgIcon.vue?vue&type=script&setup=true&lang=ts"
export * from "./SvgIcon.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SvgIcon.vue?vue&type=style&index=0&id=01ffae7e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01ffae7e",
  null
  
)

export default component.exports