import { render, staticRenderFns } from "./Option.vue?vue&type=template&id=6dcb2d60&scoped=true"
import script from "./Option.vue?vue&type=script&setup=true&lang=ts"
export * from "./Option.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Option.vue?vue&type=style&index=0&id=6dcb2d60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dcb2d60",
  null
  
)

export default component.exports